import React from 'react';
import { InfoSection } from '../components';

import DOWNLOAD_IMAGE_CONTENT from '../images/mobile-app/features-image-content-cropped.png';

export default (props) => {

    /**
     * @returns TODO: for browser support
     */
    const renderSignalForBrowser = () => {
        return (
            <div id="Browser" className="hidden">
                <div className="download-window columns">
                    <div className="column">
                        <div className="download-window-wrapper">
                            <img src="https://signal.org/assets/screenshots/download-desktop-mac-3b07c21941b2880669dd7f6143a5b20cdd0b16913f63fb774b791a04340f73d2.png" width="438" />
                        </div>
                    </div>
                </div>
                {/* <a href="https://updates.signal.org/desktop/signal-desktop-mac-universal-6.6.0.dmg" className="download-primary download-mac button large-button"> Download for Mac </a> */}
                <button className="button is-medium is-primary-button-fill is-white-txt" style={{ padding: "10px", marginTop: "20px", fontWeight: "500" }}> Use In Browser </button>

                <p> Or visit <a className="is-primary-txt" href="https://app.ewager.online">app.ewager.online</a> from your phone</p>
            </div>
        )
    }

    return (
        <div>
            <section id="download" className="download section is-primary-white-background">
                <div className="container">
                    {/* <div className="columns">
                        <div className="column is-6 has-text-centered">
                            <h2 className='title is-2'> Signal for Mobile</h2>
                        </div>
                        <div className="column is-6 has-text-centered">
                            <span className="title is-2">Information</span> <span>{" "}(BETA VERSION 0.9.0)</span>
                        </div>
                    </div> */}
                    <div className="columns">
                        <div className="column is-6 has-text-centered">
                            <h2 className='title is-2'> Ewager for Mobile</h2>
                            <div className="download-window columns">
                                <div className="column">
                                    <div className="download-window-wrapper">
                                        <img src={DOWNLOAD_IMAGE_CONTENT} width="310" />
                                    </div>
                                </div>
                            </div>
                            {/* <a className="button large-button" href="https://apps.apple.com/us/app/signal-private-messenger/id874139669"> iPhone or iPad </a><p>&nbsp;</p> */}
                            <button
                                className="button is-medium is-primary-button-fill is-white-txt"
                                onClick={() => { window.open('https://app.ewager.online') }}
                                style={{ padding: "10px", marginTop: "20px", fontWeight: "500" }}
                            > iPhone
                            </button>
                            {/* <a className="button large-button" href="https://play.google.com/store/apps/details?id=org.thoughtcrime.securesms"> Android </a> */}
                            <button
                                className="button is-medium is-primary-button-fill is-white-txt"
                                onClick={() => { window.open('https://app.ewager.online') }}
                                style={{ padding: "10px", marginTop: "20px", fontWeight: "500" }}> Android
                            </button>
                            <p> Or visit <a className="is-primary-txt" href="https://app.ewager.online">app.ewager.online</a> from your phone</p>
                        </div>
                        {/* <div className="column is-6 has-text-centered"> */}
                        <div className="column is-6">
                            <div className="has-text-centered">
                                <h2 className="title is-2 has-text-centered" style={{ display: 'inline-block' }}>Information</h2>
                                <span style={{ display: 'inline-block' }}>(BETA VERSION 0.9.0)</span>
                            </div>
                            <InfoSection />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}