import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Header, Footer, Router } from './layout/index';

const App = () => {
    const [landingPageData, setLandingPageData] = useState({})
    useEffect(() => {
        // setLandingPageData(JsonData)
    }, [])

    return (
        // takes care of space in between
        <div style={{
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: '#292929'
        }}>
            <Helmet
                title="Ewager"
                meta={[
                    { name: 'description', content: 'ewager marketing site' },
                    { name: 'keywords', content: 'bet, wager, esports, gamble, money, stellar, mobile, twitch' },
                ]}
                script={[
                    { 'src': 'https://use.fontawesome.com/releases/v5.4.1/js/all.js' },
                ]}
                link={[
                    { 'rel': 'stylesheet', 'href': 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css' }
                ]}
            />
            <Header />
            <Router />
            <Footer />
        </div>
    )
}

export default App
