import React, { useState, useEffect } from 'react';
import LOGO from '../images/ewager-white-clear-cropped.svg';

export default (props) => {
	const [isActive, setIsActive] = useState(false);
	const [isDropdownActive, setIsDropdownActive] = useState(false);

	const [currentNav, setCurrentNav] = useState('/');
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		const windowLocationArray = window.location.href.toString().split('/');
		setCurrentNav('/' + windowLocationArray[3]);
	});

	const toggleNav = () => {
		setIsActive(!isActive);
	}

	const toggleDropdown = () => {
		setIsDropdownActive(!isDropdownActive);
	}

	// const renderFaq = () => {
	// 	return (<NavLink
	// 		style={{ color: "white" }}
	// 		className="navbar-item"
	// 		to="/faq"
	// 	// activeClassName="is-active"
	// 	>
	// 		Faq
	// 	</NavLink>);
	// }

	const renderTab = (path, name) => {
		return (<a
			href={path}
			style={{ color: "white" }}
			className={`navbar-item ${currentNav === path ? 'is-active is-active-main-tab' : ''}`}>
			{name}
		</a>)
	}

	const renderTabs = () => {
		return (
			<div className={isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
				<div className="navbar-end subtitle">
					{renderTab('/install', 'Install')}
					{renderTab('/faq', 'Faq')}
					{renderTab('/contact', 'Contact')}
				</div>
			</div>);
	}

	// TODO: WE NEED TO MANAGE ALL STYLED IN HERE, and DO SOMETHING ABOUT HAMBURGER HEIGHT MATCHUP
	return (
		<nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
			<div className='container' style={{ marginTop: '5px', marginBottom: '5px' }}>
				<div className="navbar-brand">
					<a className="navbar-item" style={{ marginLeft: '0px' }} href="/" >
						<img src={LOGO} style={{ maxHeight: "none", marginLeft: '0px' }} width="200" height="200" />
					</a>
					<button className={isActive ? 'nav-hamburger button navbar-burger is-active' : 'nav-hamburger button navbar-burger'}
						onClick={toggleNav} data-target="navbarContent">
						<span style={{ background: "white" }}></span>
						<span style={{ background: "white" }}></span>
						<span style={{ background: "white" }}></span>
					</button>
				</div>
				{renderTabs()}
			</div>
		</nav>
	)
}