import React from "react"

import CREATE_BET_CROP from '../images/mobile-app/create-bet-crop.png';
import BET_HISTORY_CROP from '../images/mobile-app/bet-history-crop.png';
import BET_LIST_CROP from '../images/mobile-app/bet-list-crop.png';
import ACTIVE_BET_HISTORY_CROP from '../images/mobile-app/active-bet-history-crop.png';

export default (props) => {
    return (
        <section className="section features">
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-half has-text-centered">
                        <div className="box">
                            <img src={BET_LIST_CROP} className='image-resize image-r-corners' />
                            <h3 className="title is-secondary-txt">View Challengers</h3>
                            <p className="body2Text">View those who are ready to challenge you. They have posted their terms and are ready to make it happen.</p>
                        </div>
                    </div>
                    <div className="column is-half has-text-centered">
                        <div className="box">
                            <img src={CREATE_BET_CROP} className='image-resize image-r-corners' />
                            <h3 className="title is-secondary-txt">Create Your Bet</h3>
                            <p className="body2Text">Create your bet with the terms you choose. Pick your price. Choose Best of if you want to force multiple game plays.</p>
                        </div>
                    </div>
                </div>
                <div className="columns is-centered">
                    <div className="column is-half has-text-centered">
                        <div className="box">
                            <img src={ACTIVE_BET_HISTORY_CROP} className='image-resize image-r-corners' />
                            <h3 className="title is-secondary-txt">View Active Bets</h3>
                            <p className="body2Text">View your active bets, chat with your opponent to coordinate any requirements. Share your bet, or delete your bet if you are the owner.</p>
                        </div>
                    </div>
                    <div className="column is-half has-text-centered">
                        <div className="box">
                            <img src={BET_HISTORY_CROP} className='image-resize image-r-corners' />
                            <h3 className="title is-secondary-txt">View Past Bets</h3>
                            <p className="body2Text">View the bets you have placed in the past. See results and verify. Allows for expanding details as well.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}