import React from 'react';
import SocialMediaHeader from './SocialMediaHeader';

export default class RedditEmbedComponent extends React.Component {

    REDDIT_URL = "https://www.reddit.com/r/ewager";

    componentWillMount() {
        const script = document.createElement("script");
        script.src = "//embed.redditmedia.com/widgets/platform.js";
        script.async = true;
        document.body.appendChild(script);
    }

    renderFrame = () => {
        return (
            <div className="reddit-class">
                <blockquote className="reddit-card" data-card-created="1559671008">
                    <a href="https://www.reddit.com/r/ewager/comments/bwr9un/ewageronline_is_live/">
                        eWager.Online is Live!</a> from <a href="http://www.reddit.com/r/ewager">r/ewager</a></blockquote>
            </div>
        );
    }

    render() {
        return (<div>
            <article className="message">
                <SocialMediaHeader
                    icon={"fab fa-reddit-alien"}
                    href={this.REDDIT_URL}
                    header={"Reddit:"}
                />
                <div className="message-body">
                    {this.renderFrame()}
                </div>
            </article>
        </div>);
    }
}