import React, { useEffect, useState } from 'react';
import axios from 'axios';
const Recaptcha = require('react-recaptcha');
const SITE_KEY = '6Lem4Z8UAAAAAPwXRWMVQS4jFZqKQz4av4akRt7p';

export default (props) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("Feedback");
	const [message, setMessage] = useState("");

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit";
		script.async = true;
		document.body.appendChild(script);
	}, []);

	const validateEmail = () => {
		let emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
		if (email === '' || !emailRegex.test(email)) {
			setIsValidEmail(false);
			return false;
		}
		setIsValidEmail(true);
		return true;
	}

	const submit = async () => {
		//alert(JSON.stringify(state));
		if (firstName === '' || lastName === '' || message === '') {
			// TODO handle
			return;
		}
		if (!validateEmail()) {
			// TODO do not submit
			return;
		}
		try {
			let response = await axios.post("https://api.ewager.online/sendContactEmail", {
				firstName,
				lastName,
				email,
				message
			});
			if (response.status !== 200) {
				// return null;
				// TODO: error toast
				console.log("ERROR WITH STATUS:", response.status);
			}
		} catch (e) {
			console.log(e);
		}
		clear();
	}

	const clear = () => {
		setFirstName("");
		setLastName("");
		setIsValidEmail(true);
		setEmail("");
		setSubject("Feedback");
		setMessage("");
	}

	const onloadCallback = () => {

	}

	const verifyCallback = (response) => {
		console.log(response);
	};


	return (
		<div>
			<section className="section is-primary-white-background">
				<div className='container'>

					<div className="field">
						<label className="label is-secondary-txt">First Name</label>
						<div className="control">
							<input className="input is-secondary-border is-shadowless"
								onChange={(text) => setFirstName(text.target.value)}
								value={firstName}
								type="text"
								placeholder="Enter First Name" />
						</div>
					</div>

					<div className="field">
						<label className="label is-secondary-txt">Last Name</label>
						<div className="control">
							<input className="input is-secondary-border is-shadowless"
								onChange={(text) => setLastName(text.target.value)}
								value={lastName}
								type="text"
								placeholder="Enter Last Name" />
						</div>
					</div>

					<div className="field">
						<label className="label is-secondary-txt">Email</label>
						<div className="control has-icons-left has-icons-right">
							<input className={isValidEmail ? "input is-secondary-border is-shadowless" : "input is-danger"}
								onChange={(text) => { setEmail(text.target.value); validateEmail() }}
								value={email}
								type="email"
								placeholder="Enter Email" />
							<span className="icon is-small is-left">
								<i className="fas fa-envelope"></i>
							</span>
							{!isValidEmail &&
								<span className="icon is-small is-right">
									<i className="fas fa-exclamation-triangle"></i>
								</span>
							}
						</div>
						{!isValidEmail && <p className="help is-danger">This email is invalid</p>}
					</div>

					<div className="field">
						<label className="label is-secondary-txt">Subject Matter</label>
						<div className="field is-grouped">
							<div className="control">
								<div className="select">
									<select
										onChange={(text) => setSubject(text.target.value)}
										value={subject}>
										<option>Feedback</option>
										<option>Request</option>
										<option>Error</option>
									</select>
								</div>
							</div>
						</div>
					</div>

					<div className="field">
						<label className="label is-secondary-txt">Message</label>
						<div className="control">
							<textarea className="textarea is-secondary-border is-shadowless"
								onChange={(text) => setMessage(text.target.value)}
								value={message}
								placeholder="Textarea"></textarea>
						</div>
					</div>

					{/* 
					<div class="field">
						<div class="control">
							<label class="checkbox">
								<input type="checkbox" />
								I agree to the <a href="#">terms and conditions</a>
							</label>
						</div>
					</div>
				*/}

					<Recaptcha
						sitekey={SITE_KEY}
						render="explicit"
						size='normal'
						theme='dark'
						onloadCallback={() => onloadCallback()}
						verifyCallback={() => verifyCallback()}
					/>
					<div className="control" style={{ marginTop: "10px" }}>
						<button className="button is-primary-button-fill is-white-txt" style={{ width: "100%" }}
							onClick={() => submit()}>Submit</button>
					</div>

				</div>
			</section>
			<section className="section is-primary-white-background">
				{/* filler for footer */}
			</section>
		</div>
	)
}