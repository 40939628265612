import React, { useEffect, useState } from 'react';

export default (props) => {
	const [isActive, setIsActive] = useState(true);

	useEffect(() => {

	}, [isActive]);

	const renderAnswer = (answer) => {
		if (Array.isArray(answer)) {
			let list = [];
			for (let i = 0; i < answer.length; i++) {
				list.push(<li style={{ textAlign: 'left' }}>{answer[i]}</li>);
			}
			return (
				<div className="content">
					<ul>
						{list}
					</ul>
				</div>
			)
		}
		return (
			<p className="title is-5">{answer}</p>
		)
	}



	const { question, answer } = props;
	return (
		<div>
			<div className="card">
				<header className="card-header">
					<p className="card-header-title" onClick={() => { setIsActive(!isActive); }}>
						{question}
					</p>
					<div
						className="card-header-icon is-hidden-fullscreen"
						onClick={() => { setIsActive(!isActive); }}>
						{isActive && <span className="icon"><i className={`fas fa-angle-down`} aria-hidden="true" /></span>}
						{!isActive && <span className="icon"><i className={`fas fa-angle-up`} aria-hidden="true" /></span>}
					</div>
				</header>
				{!isActive &&
					<div id="collapsible-card" className="is-collapsible is-active" style={{ padding: '20px' }}>
						{/* <div className="card-content"> */}
						{renderAnswer(answer)}
						{/* </div> */}
						<footer className="card-footer">
						</footer>
					</div>}
			</div>
		</div>
	);

}