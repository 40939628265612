import React from 'react';

export default (props) => {
    return (
        <section className='is-primary-white-background'>
            <div className="container is-margin-top">
                <div style={{ background: "white", padding: "45px", marginRight: "10px", marginLeft: "10px" }}>
                    <h2 className="subtitle">Ewager Privacy and Cookie Policy</h2>
                    <p>
                        This Policy applies to collection of your personal data, as well as to the use of "cookie" files and similar
                        technologies on the ewager.online website (the "Website").
                    </p>

                    <br />
                    <h2 className="subtitle">Your Privacy is important to us!</h2>
                    <div className="content">
                        <ul>
                            <li>In order to enable your use of the Website and services provided through the Website, we do not require
                                that you set up an account with us. If you choose to create a user account, you are asked to provide the following personal
                                data: e-mail address and password. Ewager is the controller of your personal data and takes appropriate steps to
                                ensure security of your data.</li>
                            <li>We may have to share your personal data with competent authorities (in particular the police or prosecution service),
                                if they approach us with such a request, provided that there exists an appropriate legal basis for making your personal
                                data available to them (e.g. a court order).</li>
                            <li>Currently but subject to change, we are not using your data to the extent of marketing, advertising etc.</li>
                        </ul>
                    </div>

                    <br />
                    <h2 className="subtitle">Our use of cookies and similar technologies</h2>
                    <div className="content">
                        <ul>
                            <li>We make use of Google Analytics tools.</li>
                            <li>In the future, we may use cookie storage among other things, solely for the purpose of providing the consumer,
                                a fast and reliable client side application.
                            </li>
                            <li>Google Analytics is a tool we use in order to calculate visits to our website, check which functionalities
                                of our website are used most and improve the operation of the Website.</li>
                            <li style={{ overflowWrap: "break-word" }}>Handling and use of "cookies" is allowed automatically. However, these settings can be changed at any time. For details on
                                how cookies are handled and used, see the settings of your web browser. You can learn more about cookies and similar technologies
                                and check how to disable them for the browser you use by visiting the following website:
                                <a className="is-primary-txt" target="_blank" href='http://www.allaboutcookies.org'> http://www.allaboutcookies.org</a>.</li>
                            <li style={{ overflowWrap: "break-word" }}>If you wish to learn more about Google Analytics tools and your privacy, please visit the
                                following site:
                                <a className="is-primary-txt" target="_blank" href='https://support.google.com/analytics/answer/6004245?hl=en'> https://support.google.com/analytics/answer/6004245?hl=en</a>.
                                You can also opt out from Google Analytics by installing a browser add-on available here: <a className="is-primary-txt" target="_blank" href='https://tools.google.com/dlpage/gaoptout?hl=en-GB'> https://tools.google.com/dlpage/gaoptout?hl=en-GB</a>.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}