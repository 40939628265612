import React from 'react';

export default (props) => {
    return (
        <section className='is-primary-white-background'>
            <div className="container is-margin-top">
                <div style={{ background: "white", padding: "45px", marginRight: "10px", marginLeft: "10px" }}>
                    <h2 className="subtitle">End User Agreement</h2>
                    <p>
                        These Terms of Use constitute a binding legal agreement between you (the "User" or "you") and "Ewager", "us" or "we".
                        Please make sure you’ve read the entire Terms of Use before you start using the Ewager website available at www.ewager.online (the “Website”)
                        or any other content or services available via the Website, in particular games, betting functionalities etc. (collectively referred to as
                        “Services”). Please note that these Terms of Use, together with our Privacy and Cookie Policy, constitute an entire agreement between you and
                        Ewager.
                    </p>
                    <br />
                    <p>
                        The minimum technical requirement for you to use the Ewager Services and Website are as follows: connection to the internet,
                        web browser, Java Script.
                    </p>
                    <br />
                    <p>
                        Ewager reserves the right to change these Terms of Use from time to time for an important reason
                        (e.g. in case of (I) a material change to our business model or business circumstances, (II) the way we operate, (III)
                        as a response to legislative changes, (IV) other justifiable reasons). In any event, you will be notified of any such changes
                        via e-mail or through a pop-up notification upon logging onto your account, at least 14 days before the changes come into force.
                        The updated Terms of Use will also be posted on the Ewager Website with prior notice. By continued use of the Service,
                        you agree to be bound by the updated Terms of Use. We also encourage you to monitor the Website for any changes.
                    </p>

                    <br />
                    <h2 className="subtitle">1. Grant of license</h2>
                    <div className="content">
                        <ol type="1">
                            <li>Subject to the terms and conditions contained herein, Ewager grants the User a non-exclusive, personal,
                                non-transferable right to use the Service on your personal computer or other device that accesses the Internet
                                in order to access the games available and described on the ewager.online website (the website and app service together
                                being the "Service").</li>
                            <li>The Service is not for use by (I) individuals under 18 years of age, (II) individuals under the legal age of
                                majority in their jurisdiction and (III) individuals accessing the Service from jurisdictions from which it is illegal
                                to do so. To the maximum extent permitted by applicable law, Ewager shall not be held liable for User’s failure
                                to do so.</li>
                            <li>Ewager and its licensors are the sole holders of all rights in and to the Service and code, structure and organization,
                                including copyright, trade secrets, intellectual property and other rights. You may not, within the limits prescribed by
                                applicable laws: (a) copy, distribute, publish, reverse engineer, decompile, disassemble, modify, or translate the website;
                                or (b) use the Service in a manner prohibited by applicable laws or regulations (each of the above is an "Unauthorized Use").
                                Ewager reserves any and all rights implied or otherwise, which are not expressly granted to the User hereunder and retain
                                all rights, title and interest in and to the Service. To the extent permitted by applicable law, you agree that you will be
                                solely liable for any damage, costs or expenses arising out of or in connection with the commission by you of any
                                Unauthorized Use. You shall notify Ewager immediately upon becoming aware of the commission by any person of any
                                Unauthorized Use and shall provide Ewager with reasonable assistance with any investigations it conducts in light of the
                                information provided by you in this respect.</li>
                            <li>The term "Ewager", its domain names and any other trade marks or service marks used by Ewager as part of the
                                Service (the "Trade Marks"), are solely owned by Ewager. In addition, all content on the Website, including, but not
                                limited to, the images, pictures, graphics, photographs, animations, videos, music, audio and text (the "Site Content")
                                belongs to Ewager and is protected by copyright and/or other intellectual property or other rights. You hereby acknowledge
                                that by using the Service, you obtain no rights to the Site Content and/or the Trade Marks, or any part thereof. Under
                                no circumstances may you use the Site Content and/or the Trade Marks without Ewager's prior written consent.
                                Additionally, you agree not to do anything that will harm or potentially harm the rights, including the intellectual
                                property rights of Ewager. In particular, you undertake not to use the Website of Services in an illegal manner or provide
                                unlawful content through the Website or Services.</li>
                        </ol>
                    </div>

                    <br />
                    <h2 className="subtitle">2. No warranties</h2>
                    <p>REGARDLESS OF OUR EFFORTS, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE MAKE NO WARRANTY
                        THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY OR ERROR-FREE.</p>

                    <br />
                    <h2 className="subtitle">3. Your representations and warranties</h2>
                    <p>Prior to your use of the Service and on an ongoing basis, to the maximum extent permitted by
                        applicable law, you represent, warrant, covenant and agree that:</p>
                    <div className="content">
                        <ol type="1">
                            <li>there is a risk of losing cryptocurrency supported by our service when using the Service and that Ewager has
                                no responsibility to you for any such loss if it comes from you betting decision;</li>
                            <li>your use of the Service is at your choice, discretion and risk;</li>
                            <li>you are solely responsible for any applicable taxes which may be payable on cryptocurrency supported by our service
                                and awarded to you through your using the Service;</li>
                            <li>the telecommunications networks and Internet access services required for you to access and use the Service are entirely
                                beyond the control of Ewager and Ewager shall have no liability whatsoever for any outages, low connection speed,
                                capacity constraints or other deficiencies affecting the same;</li>
                            <li>you are aged 18 or over and that you are not currently self-excluded from any gambling site or gambling premises and
                                that you will inform us immediately if you enter into a self-exclusion agreement with any gambling provider,
                                should applicable jurisdiction provide for an option to participate in self-exclusion scheme;</li>
                        </ol>
                    </div>

                    <br />
                    <h2 className="subtitle">4. Breach</h2>
                    <div className="content">
                        <ol type="1">
                            <li>Without prejudice to any other rights, if a User breaches in whole or in part any provision contained herein,
                                Ewager reserves the right to take such action as it sees fit, including terminating your agreement with us based on these
                                Terms of Use or any other agreement in place with the User and/or taking legal action against such User.</li>
                            <li>To the maximum extent permitted by applicable law, you agree to fully indemnify, defend and hold harmless Ewager and its
                                shareholders, directors, agents and employees from and against all claims, demands, loss liabilities, damages, losses, costs and expenses,
                                including legal fees and any other charges whatsoever, caused by howsoever caused, that may arise as a result of: (I) your breach of
                                these Terms of Use, in whole or in part; (II) violation by you of any law or any third party rights; and (III) use by you of the Service.</li>
                        </ol>
                    </div>

                    <br />
                    <h2 className="subtitle">5. Limitation of liability</h2>
                    <div className="content">
                        <ol type="1">
                            <li>To the maximum extent permitted by applicable law, under no circumstances, including negligence, shall Ewager be liable
                                for any special, incidental, direct, indirect or consequential damages whatsoever (including, without limitation, damages
                                for loss of business profits, business interruption, loss of business information, or any other pecuniary loss) arising
                                out of the use (or misuse) of the Service even if Ewager had prior knowledge of the possibility of such damages.</li>
                            <li>Nothing in these Terms of Use shall exclude or limit Ewager’s liability for death or personal injury
                                resulting from its negligence.</li>
                        </ol>
                    </div>

                    <br />
                    <h2 className="subtitle">6. Disputes</h2>
                    <div className="content">
                        <p>If a User wishes to make a complaint, please contact our customer service team at contact@ewager.com. Should any
                            dispute not be resolved to your satisfaction you may pursue remedies in the governing law jurisdiction set forth below.
                            We will respond to your complaints within 14 working days, to an e-mail address you used to contact us.</p>
                    </div>

                    <br />
                    <h2 className="subtitle">7. Severability</h2>
                    <div className="content">
                        <p>If a provision of these Terms of Use is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect
                            the validity or enforceability in that jurisdiction of any other provision hereof or the validity or enforceability in
                            other jurisdictions of that or any other provision hereof.</p>
                    </div>

                    <br />
                    <h2 className="subtitle">8. Creation of user account</h2>
                    <div className="content">
                        <p>To use some of Ewager's services, you will need to create a user account. Each user can create only one account, associated with one
                            valid e-mail address. In order to create an account, you are required to sign up by filling in a sign-up form available
                            on the Website. During the sign-up process, you are asked to provide your current e-mail address and password.
                            You can change your password at any time in the settings panel. By creating the user account you acknowledge that you
                            consent to these Terms of Use and our Privacy and Cookie Policy.</p>
                    </div>

                    <br />
                    <h2 className="subtitle">9. Gamble Aware</h2>
                    <div className="content">
                        <p><b>Responsible Gambling:</b></p>
                        <ul>
                            <li>For the majority of people, gambling is an enjoyable leisure and entertainment activity. However, for some,
                                it can have negative impacts leading to what it’s called a gambling problem.</li>
                            <li> Responsible gambling means that the individual has evaluated the risk of losing their money and is
                                gambling for pleasure and/or entertainment. In this case, gambling activities are under
                                control of individual.</li>
                            <li>At Ewager we offer a platform and services that enable our clients to enjoy gaming and betting. Our aim is to
                                provide our customers with the best experience, while providing a safe & secure environment.
                                Our team is committed to excluding cases of betting while underage or let gambling control lives.</li>
                        </ul>

                        <p><b>Gambling Problem:</b></p>
                        <p>Gambling Problem occurs when there is an urge to continuously gamble despite harmful negative consequences to
                            personal/family/work life or a desire to stop.
                            <br />
                            Gambling Problem can lead to mental health problems, family conflict, employment issues and possible negative legal ramifications.
                            <br />
                            If you have any concerns about your gambling habits we strongly suggest you to seek advice from accredited
                            organisations that can offer support and advice:</p>
                        <ul>
                            <li><a className="is-primary-txt" target="_blank" href="https://www.begambleaware.org/">BeGambleAware</a></li>
                            <li><a className="is-primary-txt" target="_blank" href="http://www.gamblersanonymous.org/ga/">Gamblers Anonymous</a></li>
                            <li><a className="is-primary-txt" target="_blank" href="https://www.gamblingtherapy.org/en">Gambling Therapy</a></li>
                            <li><a className="is-primary-txt" target="_blank" href="https://www.gamcare.org.uk/">GamCare</a></li>
                            <li><a className="is-primary-txt" target="_blank" href="https://keepitfun.rank.com/">Keep It Fun</a></li>
                        </ul>

                        <p><b>Underage Members:</b></p>
                        <p>If you wish to participate in gambling activities at Ewager you must be over 18 years of age or the legal minimum age for
                            gambling in the jurisdiction you reside in and legally allowed, under the laws applicable to you.
                            <br />
                            If you have any minors living in your household we recommend to review the following parental control software links,
                            which could prove a useful tool, regarding control and restrictions of the content accessible on your devices:</p>
                        <ul>
                            <li><a className="is-primary-txt" target="_blank" href="https://www.cyberpatrol.com/">Cyber Patrol</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}