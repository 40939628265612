import React from 'react'
import CookieConsent from "react-cookie-consent";
import { AppLinks } from '../components';
const DISCORD_URL = "https://discord.gg/Jdxr9rT";

export default () => (
	<footer className="footer"
		style={{
			color: "white",
			// alignContent: "center",
			// alignItems: "center",
			// alignSelf: "center",
			background: "#292929",
			paddingBottom: '40px'
		}}
	>
		<div className="container">
			<div className="columns">

				{/* INFO COLUMN */}
				<div className="column">
					<div className="content" >
						<h5 className="title has-text-white is-5" style={{ marginBottom: '8px' }}>
							Info
						</h5>
						<a className="is-primary-txt" href="/privacy"> Privacy Policy </a> |
						<a className="is-primary-txt" href="/terms"> Terms Of Service</a>
						<p> Powered By <strong className="is-primary-txt">ewager.online</strong> <br />
							Copyright {new Date().getFullYear()} <br />
						</p>
					</div>
				</div>

				{/* RESOUCES COLUMN */}
				<div className="column">
					<h5 className="title has-text-white is-5" style={{ marginBottom: '8px' }}>
						Resources
					</h5>
					<ul>
						<li>
							<a className="link has-text-white" href={DISCORD_URL}>
								<i className="fab fa-discord"></i>
								{" "}Discord
							</a>
						</li>
						<li>
							<a className="link has-text-white" href="/contact">
								<i className="fa fa-envelope"></i>
								{" "}Contact Us
							</a>
						</li>
					</ul>
				</div>

				{/* APP LINK COLUMN */}
				<div className="column">
					<h5 className="title has-text-white is-5" style={{ marginBottom: '8px' }}>
						Install
					</h5>
					<ul>
						<li>
							<AppLinks androidDisplay={true} iosDisplay={true} />
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
)

// TODO: remove 
{/* <CookieConsent
	buttonStyle={{ background: "white" }}>
	This website uses cookies to enhance the user experience.
</CookieConsent> */}