import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import SocialMediaHeader from './SocialMediaHeader';

export default class TwitterEmbedComponent extends React.Component {

    TWITTER_URL = "https://twitter.com/ewageronline";

    componentWillMount() {
    }

    renderFrame = () => {
        return (
            <div>
                <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="ewageronline"
                    options={{ height: "100%" }}
                />
            </div>
        );
    }

    render() {
        return (<div>
            <article className="message">
                <SocialMediaHeader
                    icon={"fab fa-twitter"}
                    href={this.TWITTER_URL}
                    header={"Twitter Feed:"}
                />
                <div className="message-body">
                    {this.renderFrame()}
                </div>
            </article>
        </div>);
    }
}