import React, { useEffect, useState } from 'react';
import '../main.css';
import { InfoSection, Features } from '../components';
import bulmaCarousel from 'bulma-carousel';

import SCREENSHOT_1_IOS from '../images/mobile-app/mocked-iphone/IMG_2190_iphone13midnight_portrait.png';
import SCREENSHOT_2_ANDROID from '../images/mobile-app/mocked-android/IMG_2185_google-pixel5-justblack-portrait.png';
import CLASH_ROYALE_IMAGE from '../images/game-images/clash-royale-game-image.jpg';
import HALO_INFINITE_IMAGE from '../images/game-images/halo-infinite-game-image.jpg';
import NHL_23_IMAGE from '../images/game-images/nhl-23-game-image.jpg';
import NBA_23_IMAGE from '../images/game-images/nba-23-game-image.jpg';
import APEX_LEGENDS_IMAGE from '../images/game-images/apex-legends-game-image.jpg';

const SLIDES = [
	{
		url: CLASH_ROYALE_IMAGE,
		title: "Clash Royale",
		desc: "Supported! Clash Royale mobile game betting as our primary game type."
	},
	{
		url: HALO_INFINITE_IMAGE,
		title: "Halo Infinite",
		desc: "We currently have halo within the beta process of our betting support."
	},
	{
		url: NHL_23_IMAGE,
		title: "NHL 2023",
		desc: "We currently in the process of support this game."
	},
	{
		url: NBA_23_IMAGE,
		title: "NBA 2K3",
		desc: "We currently in the process of support this game."
	},
	{
		url: APEX_LEGENDS_IMAGE,
		title: "APEX Legends",
		desc: "We currently in the process of support this game."
	}
]

export default (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isInfoSection, setIsInfoSection] = useState(false);
	const [slideShow, setSlideShow] = useState(null);
	const [tab, setTab] = useState(0);

	useEffect(() => {
		generateSlideShow();
	}, []);

	useEffect(() => {
		// leavin init here as note, you can perform actions after attached elsewhere
		const carousels = bulmaCarousel.attach('.carousel-main', {
			slidesToScroll: 1,
			slidesToShow: 1,
			duration: 1000,
			autoplay: true,
			infinite: true
		});
	}, [slideShow]);


	const generateSlides = (slideItems = SLIDES || []) => {
		let genSlides = [];
		let count = 0;
		for (let item of slideItems) {
			// for (let i = 0; i < 10; i++) {
			count++;
			genSlides.push(
				<div className="slider-item" data-slider-index={count} style={{ width: '353px' }}>
					<div key={count} className="card" style={{ alignContent: 'center', alignSelf: 'center', alignItems: 'center' }}>
						{/* <div className="card-image"> */}
						{/* <figure className="image is-covered" style={{ width: '337px', height: '190px' }}> */}
						<figure className="image is-covered" style={{ width: '100%', height: '190px' }}>
							<img src={item.url} alt="" />
						</figure>
						{/* </div> */}
						<div className="card-content">
							<div className="title is-4">
								{item.title}
							</div>
							<div className="subtitle is-6">
								{item.desc}
							</div>
						</div>
					</div>
				</div>
			);
		}
		return genSlides;
	}

	const generateSlideShow = () => {
		const slides = generateSlides();
		if (slides.length < 1) {
			return <div></div>
		}

		setSlideShow(
			<section className="section" >
				<div className="carousel-main">
					{slides}
				</div>
			</section>
		)
	}

	const container1 = () => {
		return (
			<div style={{ background: "rgba(0, 0, 0, 0)" }}>
				<h1 className="title is-secondary-txt">Betting Made Easier & Cheaper For You</h1>
				<h2 className="subtitle is-secondary-txt">Ewager is an open platform for users to perform Esports betting using cryptocurrency.
					We currently support Stellar with ETH, and BTC on the way.</h2>
			</div>
		);
	}

	const container2 = () => {
		return (
			<div style={{ background: "rgba(0, 0, 0, 0)" }}>
				<h1 className="title is-secondary-txt">What is Stellar?</h1>
				<h2 className="subtitle is-secondary-txt">Stellar, denoted as XLM is a cryptocurrency backed by
					IBM to allow for quick, easy and verifiable transactions on a decentralized network.</h2>
			</div>
		);
	}

	const renderNewMedia = () => {
		return (
			<section className="hero-main hero" >
				<div className="hero-body">
					<div className="container">
						<div className="columns">
							<div className="column is-two-fifths">
								<h1 className='title is-1'>Bet Freely</h1>
								<p className="body1">Say "It's On" with a new type of betting experience. We put the focus on your ability to bet for yourself against the world. With a mobile first management experience.</p>
								{/* <p>
									<br />
									<a className="get-signal button button-white" href="/download/"> Get Ewager {" "}<span className="icon">
										<i className="icon icon-external-link-alt"></i>
									</span></a>
								</p> */}
								<button
									className="button is-medium is-primary-white-button-fill"
									style={{ padding: "10px", marginTop: "20px", fontWeight: "500" }}
									onClick={() => { window.location.replace('https://ewager.online/install') }}
								> Get Ewager
								</button>
							</div>
							<div className="column mobile-screenshots">
								<img className="screenshot1" src={SCREENSHOT_1_IOS} />
								<img className="screenshot2" src={SCREENSHOT_2_ANDROID} />
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}

	return (
		<div className='is-primary-white-background' style={{ marginTop: "0px", padding: "0px" }}>
			{renderNewMedia()}
			<div className='is-primary-white-background' style={{ padding: "10px" }}>
				{slideShow}
				{isInfoSection && InfoSection()}
				<div className='container' style={{ marginTop: "30px", padding: "10px" }}>
					<div className="columns">
						<div className="column is-half">{container1()}</div>
						<div className="column is-half">{container2()}</div>
					</div>
				</div>
				<Features />
			</div>
		</div>
	)
}