import styled from 'styled-components'

/**
 * @deprecated, remove this 
 */
const Site = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 100vh;
	background: #292929;
`
export default Site