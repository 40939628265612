import React from 'react';
import SocialMediaHeader from './SocialMediaHeader';

export default class DiscordEmbedComponent extends React.Component {

    DISCORD_URL = "https://discord.gg/Jdxr9rT";

    componentWillMount() {
    }

    renderFrame = () => {
        return (
            <div>
                <iframe
                    src="https://discordapp.com/widget?id=560692460075089931&theme=dark"
                    width="100%"
                    height="auto"
                    allowtransparency="true"
                    frameBorder="0"
                />
            </div>
        );
    }

    render() {
        return (<div>
            <article className="message">
                <SocialMediaHeader
                    icon={"fab fa-discord"}
                    href={this.DISCORD_URL}
                    header={"Discord Channel:"}
                />
                <div className="message-body">
                    {this.renderFrame()}
                </div>
            </article>
        </div>);
    }
}