import React from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home, Contact, Terms, Faq, Privacy, Install } from '../Pages';

const router = createBrowserRouter([
	{
		path: "/",
		element: <Home />
	},
	{
		path: "/Install",
		element: <Install />
	},
	{
		path: "/contact",
		element: <Contact />
	},
	{
		path: "/faq",
		element: <Faq />
	},
	{
		path: "/terms",
		element: <Terms />
	},
	{
		path: "/privacy",
		element: <Privacy />
	},
]);

export default () => (<RouterProvider router={router} />);