import React from 'react';
import { YoutubeVideo } from '../components';
import QaComponent from '../components/QaComponent'

const FULL_QUICKSTART_GUIDE = 'qfKIL4nxQtg';
const SETUP_STELLARX_WALLET = 'SKgotlP97XM';
const PLACE_BET = 'wH6VJ1ck238';
const ESPORTS_PROMO_CODE = '67BYcB8ZqZo';


const HEADER_STYLE = {
  marginTop: "30px",
  marginRight: "10px",
  marginLeft: "10px",
  marginBottom: '10px',
}

export default (props) => {

  /**
   * TODO: make video guides
   */
  const renderVideoGuide = () => {
    return (
      <div>
        <h1
          className="title"
          style={{
            marginTop: "10px",
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: '0px',
            textAlign: 'center'
          }}>Video Guides:</h1>
        <div className="columns">
          <div className="column is-half"> <YoutubeVideo title={"Full Quickstart Guide:"} videoId={FULL_QUICKSTART_GUIDE} /> </div>
          <div className="column is-half"> <YoutubeVideo title={"Setup StellarX Wallet:"} videoId={SETUP_STELLARX_WALLET} /> </div>
        </div>
        <div className="columns">
          <div className="column is-half"> <YoutubeVideo title={"Place a Bet:"} videoId={PLACE_BET} /> </div>
          <div className="column is-half"> <YoutubeVideo title={"Esports Promo Code:"} videoId={ESPORTS_PROMO_CODE} /> </div>
        </div>
      </div>
    )
  }

  const renderGeneralQuestions = () => {
    return (
      <div style={{ marginBottom: '20px' }}>
        <h1
          className="title"
          style={HEADER_STYLE}>
          General Ewager Online (EWO) Information:
        </h1>

        <div style={{ padding: '10px' }}>
          <div className="columns">
            <div className="column is-half">
              <QaComponent
                question={"Do I need to create an account on Ewager?"}
                answer={[`Account creation (which consists of email verification at the moment) is required in order to get into the platform. 
                All that you have to do is recieve verification code to create or login into the account.`]}>
              </QaComponent>
            </div>

            <div className="column is-half">
              <QaComponent
                question={"Which forms of currency does Ewager currently support?"}
                answer={[`Ewager currently supports the use of Stellar (XLM) as the method of means for digital asset exchange among betting players. More are on the way.`]}>
              </QaComponent>
            </div>
          </div>

          <div className="columns">
            <div className="column is-half">
              <QaComponent
                question={"What is Stellar (XLM)?"}
                answer={[`Stellar, denoted as XLM, is a cryptocurrency backed by IBM to allow for quick, easy, and verifiable transactions on a decentralized network.`,
                  <a href='https://www.mycryptopedia.com/stellar-lumens-xlm-explained/'>Stellar Lumens XLM Explained</a>,
                  <a href='https://www.stellar.org/papers/stellar-consensus-protocol.pdf'>Stellar Lumens (XLM) Whitepaper</a>]}>
              </QaComponent>
            </div>

            <div className="column is-half">
              <QaComponent
                question={"Is there a native IOS or Android App?"}
                answer={['Not yet, but this application is ready to be compiled to a native app. Getting it posted on the store will be next steps, and of course more updates to come.']}>
              </QaComponent>
            </div>
          </div>

          <div className="columns">
            <div className="column is-half">
              <QaComponent
                question={"Something went wrong!  How do I contact Ewager Tech Support?"}
                answer={[(<div>Please fill out a brief report on our contact page at
                  <a href='https://ewager.online/contact'> https://ewager.online/contact.</a></div>),
                  `Users should expect a response within 24 hours.`]}>
              </QaComponent>
            </div>

            <div className="column is-half">
              <QaComponent
                question={"How long does it take for Ewager Tech Support to respond?"}
                answer={[`Users should receive a response regarding technical support inquiries within 48 hours.`]}>
              </QaComponent>
            </div>
          </div>

          {/* <div className="columns">  </div> */}
        </div>
      </div>
    )
  }

  return (
    <section className='is-primary-white-background'>
      <div className="container is-margin-top">

        <h1 className="title" style={{ ...HEADER_STYLE, marginTop: '40px' }}>Users Guide</h1>
        <div style={{ padding: '10px' }}>
          <p style={{ fontSize: 18 }}>
            Currently right now we support the use of <a className="is-primary-txt" target="_blank" href="https://www.stellar.org/">stellar </a>
            as the method of means for digital asset exchange among betting players. It is important to note that popular exchanges like
            <a className="is-primary-txt" target="_blank" href="https://www.coinbase.com/"> coinbase</a>,
            <a className="is-primary-txt" target="_blank" href="https://www.binance.com/"> binance</a>, and some others do not send and recieve payment
            from the same wallet, they are often pooled for obfuscation reasons among other things.
            <br /><br />
            If you are going to use our service, we have thought of this for you, you can choose your payout wallet address when you create a bet or join a bet. Currently you must have
            a saved wallet which auto loads that address for you. That wallet is (as in the disclaimer, not used by us in any unsafe way) and is used to submit to the escrow transactions only.</p>
          <br />
          <h2 className="subtitle">Installation Guide Can Be Found <a className="is-primary-txt" target="_blank" href="/install"> Here</a>.</h2>

          <p style={{ fontSize: 18 }}>If you are looking for more details on how to create, fund, and control a stellar wallet, options can be found
            <a className="is-primary-txt" target="_blank" href="https://www.stellar.org/lumens/wallets/"> here</a>.
          </p>
        </div>
        {renderGeneralQuestions()}
      </div>
    </section>
  );
}