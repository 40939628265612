import React from 'react';

export default class SocialMediaHeader extends React.Component {

    render() {
        return (<div className="message-header article-header">
            <p>
                <i className={this.props.icon} /> <a style={{ textDecoration: "none" }} href={this.props.href}>{this.props.header}</a>
            </p>
        </div>);
    }
}