import React from "react";
const WEBAPP_URL = "https://app.ewager.online";
const INSTALL_URL = "https://ewager.online/install";
const ANDROID_URL = 'https://s3.amazonaws.com/ewager-development-mobile-apps/android/ewager-android-release.apk';
const IOS_URL = 'https://s3.amazonaws.com/ewager-development-mobile-apps/ios/ewager-ios-release.ipa';

export default (props) => {
    return (
        <div className="field is-grouped is-grouped-multiline">
            {props.webDisplay && <div className="control">
                <a className="button is-dark"
                    style={{ background: "rgba(0, 128, 128, 0.7)" }}
                    target="_blank" href={WEBAPP_URL}>PWA Mobile Web App</a>
            </div>}
            {props.androidDisplay && <div className="control">
                <a className="button is-dark" style={{ background: "rgba(0, 128, 128, 0.7)" }} href={INSTALL_URL}>
                    <span className="icon is-large">
                        <i className="fab fa-2x fa-android" />
                    </span>
                </a>
            </div>
            }
            {props.iosDisplay && <div className="control">
                <a className="button is-dark" style={{ background: "rgba(0, 128, 128, 0.7)" }} href={INSTALL_URL}>
                    <span className="icon is-large">
                        <i className="fab fa-2x fa-apple" />
                    </span>
                </a>
            </div>}
        </div>
    )
}