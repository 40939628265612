import React from "react"

export default (props) => {
    return (
        <div>
            <section className="hero is-primary-white-background is-small is-bold">
                {/* <div class="hero-head"> </div> */}
                <div className="hero-body" style={{ paddingTop: '0px' }}>
                    <div className="container">
                        {/* has-text-centered */}
                        {/* <h1 className="title is-3">
                            Information (BETA VERSION 0.9.0)
                        </h1> */}
                        {props.hasTitle && <><span className="title is-3">Information</span> <span>{" "}(BETA VERSION 0.9.0)</span>
                            <div style={{ marginBottom: '10px' }} /></>}

                        <h2 className="subtitle">
                            <span className="has-text-weight-bold">What Is Currently Supported:</span>
                            <li className='is-margin-left-li'>Stellar Currency</li>
                            <li className='is-margin-left-li'>PWA (Progressive Web App)</li>
                        </h2>
                        <h2 className="subtitle">
                            <span className="has-text-weight-bold">How To Install On Mobile:</span> <br />
                            <ol className="is-margin-left-ol">
                                <li className='is-margin-left-li'>Open up your IOS (Safari) or Android (Chrome Browser)</li>
                                <li className='is-margin-left-li'>Open the PWA Link to the left.</li>
                                <li className='is-margin-left-li'>Click settings drop button and select add to home screen.</li>
                                <li className='is-margin-left-li'>Open up the added to home screen icon and use like a native app!</li>
                            </ol>
                        </h2>
                        <h2 className="subtitle">
                            <span className="has-text-weight-bold">Notes:</span> <br />
                            <li className='is-margin-left-li'>Native Android And IOS App Coming Soon.</li>
                            <li className='is-margin-left-li'>The app does function largely natively, to refresh main list click the icon at the top left.</li>
                            <li className='is-margin-left-li'>Also more than free to use in mobile browser as well, no installation needed.</li>
                        </h2>
                        {/* <h2 className="subtitle">
                            Future:<br />
                            <li className='is-margin-left-li'>More Games</li>
                            <li className='is-margin-left-li'>Changes based on feedback</li>
                        </h2> */}
                        <h2 className="subtitle has-text-centered">Please <a className="is-primary-txt" href="https://info.ewager.online/contact">contact</a> if you have questions or want to support.</h2>
                    </div>
                </div>
            </section>
        </div>
    );
}